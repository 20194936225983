import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from '../../../styles/organisms/gallery.module.scss'
import { addSectionToBottom, addSectionToTop, fetchImages } from '../../../lib/gallery'

const Gallery = () => {
  const containerEl = useRef(null)
  const sectionWrapperEl = useRef(null)
  const [imageList, setImageList] = useState([])

  // 画像のfetchとレイアウト
  useEffect(() => {
    ;(async () => {
      const result = await fetchImages()
      setImageList(result)

      // スクロール位置 初期値
      const sectionItemData = sectionWrapperEl.current.firstElementChild.getBoundingClientRect()
      const firstScrollPosition = sectionItemData.height
      containerEl.current.scrollTo(0, firstScrollPosition)
    })()
  }, [])

  // 無限スクロール

  const isScrollToggle = useCallback(() => {
    const scrollTop = containerEl.current.scrollTop

    const containerData = containerEl.current.getBoundingClientRect()
    const sectionWrapperData = sectionWrapperEl.current.getBoundingClientRect()
    const sectionItemData = sectionWrapperEl.current.firstElementChild.getBoundingClientRect()

    const remainButtom = sectionWrapperData.height - scrollTop - containerData.height
    const nextPositionAtBottom = sectionWrapperData.height - window.innerHeight - sectionItemData.height * 2
    const nextPositionAtTop = sectionItemData.height * 2

    if (remainButtom < sectionItemData.height) {
      addSectionToBottom(sectionWrapperEl)
      containerEl.current.scrollTo(0, nextPositionAtBottom)
    }
    if (scrollTop < sectionItemData.height) {
      addSectionToTop(sectionWrapperEl)
      containerEl.current.scrollTo(0, nextPositionAtTop)
    }
  }, [])

  useEffect(() => {
    const container = containerEl.current
    container.addEventListener('scroll', isScrollToggle, { passive: true })
    return () => {
      container.removeEventListener('scroll', isScrollToggle, { passive: true })
    }
  }, [])

  // 常時スクロール

  const [currentPosition, setCurrentPosition] = useState(0)

  const ordinarilyScroll = useCallback(() => {
    setInterval(() => {
      setCurrentPosition(containerEl.current.scrollTop + 1)
    }, 50)
  }, [])

  useEffect(() => {
    containerEl.current.scrollTo(0, currentPosition)
  }, [currentPosition])

  useEffect(() => {
    window.addEventListener('load', ordinarilyScroll)
    return () => {
      window.removeEventListener('load', ordinarilyScroll)
    }
  }, [])

  return (
    <>
      <div className={styles.container} ref={containerEl}>
        <div ref={sectionWrapperEl}>
          {imageList !== [] &&
            imageList.map((patternSection, index) => {
              return (
                <div className={'pattern-section section-' + index} key={'section-' + index}>
                  {patternSection.map((item) => {
                    return (
                      <div className={'item ' + item.img_shape} key={item.post_id}>
                        <div className='img_wrapper'>
                          {item.img_height > item.img_width ? (
                            <img
                              src={item.img_src}
                              srcSet={item.img_srcset}
                              width={item.img_width}
                              height={item.img_height}
                              alt=''
                              sizes='(min-width: 439px) 50vw, (min-width: 1023px) 33vw, 25vw'
                            />
                          ) : (
                            <img
                              src={item.img_src}
                              srcSet={item.img_srcset}
                              width={item.img_width}
                              height={item.img_height}
                              alt=''
                              sizes='(min-width: 439px) 100vw, (min-width: 1023px) 66vw, 50vw'
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default Gallery
