import React from 'react'
import styles from '../../../styles/components/input.module.scss'

// type Props = {
//   dataName: string
//   disabled: boolean
//   id?: string
//   isValid?: boolean
//   multiline?: boolean
//   onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
//   placeholder?: string
//   required: boolean
//   rows?: number
//   type: string
//   value: string | number
// }

const TextInput = React.memo((props) => {
  const { dataName, disabled, id, isValid, multiline, onChange, placeholder = '', required, rows, type, value } = props

  const className = (() => {
    if (disabled) {
      return styles.text_disabled
    }
    if (!isValid) {
      return styles.text_error
    }
    return styles.text
  })()

  return (
    <div className={className}>
      {multiline ? (
        <textarea
          name={dataName}
          id={id}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          rows={rows}
          value={value}
        >
          {value}
        </textarea>
      ) : (
        <input
          name={dataName}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          required={required}
          type={type}
          value={value}
        />
      )}
    </div>
  )
})

export default TextInput
