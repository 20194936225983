export const addSectionToBottom = (el) => {
  const parent = el.current
  parent.appendChild(parent.firstElementChild)
}

export const addSectionToTop = (el) => {
  const parent = el.current
  parent.insertBefore(parent.lastElementChild, parent.firstElementChild)
}

// ランダム抽出
export const randomSelect = (array) => {
  const num = Math.floor(Math.random() * array.length)
  const item = array[num]

  // 画像のランダム抽出の場合は、抽出された画像を削除
  if (!Array.isArray(array[0])) {
    array.splice(num, 1)
  }
  return item
}

// ランダム並び替え
export const shuffle = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const fetchImages = () => {
  // パターン定義
  const patternA = ['h', 'v', 'v', 'v', 'v']
  const patternB = ['v', 'v', 'v', 'v', 'h']

  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_endpoint_items_garelly)
      .then((response) => {
        return response.json()
      })
      .then((res) => {
        const vImages = []
        const hImages = []

        // 縦と横で画像を分ける
        res.forEach((imageItem) => {
          if (imageItem.img_shape === 'vertical') {
            vImages.push(imageItem)
          } else if (imageItem.img_shape === 'horizontal') {
            hImages.push(imageItem)
          }
        })
        resolve({ vImages: vImages, hImages: hImages })
      })
      .catch((error) => {
        console.log(error)
        reject('failed')
      })
  }).then((result) => {
    const { vImages, hImages } = result
    const sectionList = []
    // https://www.javadrive.jp/javascript/for/index10.html

    // Horizontalを含む配列を作成
    let patternAorB
    const hImagesLength = hImages.length

    for (let i = 0; i < hImagesLength; i++) {
      const section = []

      // セクション内のパターンABを交互に指定
      if (i % 2 === 0) {
        patternAorB = patternA
      } else {
        patternAorB = patternB
      }

      patternAorB.forEach((vorh) => {
        let selectedImage
        if (vorh === 'v') {
          selectedImage = randomSelect(vImages)
        } else if (vorh === 'h') {
          selectedImage = randomSelect(hImages)
        }

        section.push(selectedImage)
      })
      sectionList.push(section)
    }

    // 残りのVertical画像の配列の作成

    const forNum = Math.floor(vImages.length / 6)

    for (let i = 0; i < forNum; i++) {
      const section = []

      for (let i = 0; i < 6; i++) {
        let selectedImage
        selectedImage = randomSelect(vImages)
        section.push(selectedImage)
      }
      sectionList.push(section)
    }

    // sectionList内を並び替え

    const shuffledSectionList = shuffle(sectionList)

    return shuffledSectionList
  })
}
