import { useCallback, useState } from 'react'
import axios from 'axios'
import { TextInput } from '../../atoms/Form'
import styles from '../../../styles/organisms/contact.module.scss'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [resMessage, setResMessage] = useState('')
  const [resStyle, setResStyle] = useState('')

  const handleName = useCallback(
    (e) => {
      setName(e.target.value)
    },
    [setName]
  )

  const handleEmail = useCallback(
    (e) => {
      setEmail(e.target.value)
    },
    [setEmail]
  )

  const handleMessage = useCallback(
    (e) => {
      setMessage(e.target.value)
    },
    [setMessage]
  )

  const convertJsontoUrlencoded = (obj) => {
    let str = []
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
      }
    }
    return str.join('&')
  }

  const handleSubmit = () => {

    const endpoint = process.env.REACT_APP_endpoint_contact
    const params = new FormData()
    params.append('yourName', name)
    params.append('yourEmail', email)
    params.append('yourMessage', message)

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
    }

    axios
      .post(endpoint, params, axiosConfig)
      .then((res) => {
        setResMessage(res.data.message)
        setResStyle(res.data.status)

        if (res.data.status === 'mail_sent') {
          setName('')
          setEmail('')
          setMessage('')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className={styles.container}>
      <div className='contents_container'>
        <div className='wrapper'>
          <label>
            <span>お名前</span>
            <TextInput
              dataName={'yourName'}
              disabled={false}
              id={'name'}
              isValid={true}
              multiline={false}
              onChange={handleName}
              placeholder={'お名前'}
              required={true}
              rows={1}
              type={'text'}
              value={name}
            />
          </label>

          <label>
            <span>メールアドレス</span>
            <TextInput
              dataName={'yourEmail'}
              disabled={false}
              id={'email'}
              isValid={true}
              multiline={false}
              onChange={handleEmail}
              placeholder={'メールアドレス'}
              required={true}
              rows={1}
              type={'text'}
              value={email}
            />
          </label>

          <label>
            <span>メッセージ</span>
            <TextInput
              dataName={'yourMessage'}
              disabled={false}
              id={'message'}
              isValid={true}
              multiline={true}
              onChange={handleMessage}
              placeholder={'メッセージ'}
              required={true}
              rows={4}
              value={message}
            />
          </label>

          <button onClick={() => handleSubmit()}>送信</button>

          <div className={resStyle !== '' ? 'response show ' + resStyle : 'response'}>{resMessage}</div>

          <div className='note'>
            <h2>注意事項</h2>
            <ul>
              <li>女性の方、未成年の方のご依頼は、お断りさせていただいております。</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
