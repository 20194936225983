import {useEffect, useState} from 'react'
import sanitize from 'sanitize-html'
import styles from '../../../styles/organisms/profile.module.scss'

const Profile = () => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [image, setImage] = useState({})

  useEffect(() => {
    fetch(process.env.REACT_APP_endpoint_profile)
    .then(response => {
      return response.json();
    }).then(res => {
    
    setTitle(res.title.rendered)
    setContent(sanitize(res.content.rendered, { allowedTags: false, allowedAttributes: { button: ['class']} }))
    setImage(res.thumbnail)
    
    }).catch(error => {
      console.log(error);
    });    
  },[])

  return (
    <div className={styles.container} >
      <div className='contents_container'>
        <div className='wrapper'>
          <div className='image'>
            {image.url && <img src={image.url} srcSet={image.srcset} width={image.width} height={image.height} alt="portrait" />}
          </div>

          <div className='text'>
            <h1 className='title'>{title}</h1>
            <div className='content' dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>        
      </div>
    </div>
  )

}

export default Profile