import React, {useEffect, useRef, useState} from "react";
import styles from '../../../styles/components/pagination.module.scss'

const Pagination = (props) => {
  const {sum, per, onChange} = props
  const pageRange = 2

  // 初回レンダリングかどうかを判定するための変数
  const isFirstRender = useRef(true);
  // 現在のページ番号
  const [currentPage, setPage] = useState(1);

  useEffect(() => {
    // 初回レンダリング時はスキップし、変数を更新する
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // 親コンポーネントにpage番号を渡す
    onChange({ page: currentPage });
  }, [currentPage, onChange]);

  // ページ数
  const totalPage = Math.ceil(sum / per);

  // 「<」がクリックされたときの処理
  const handleBack = () => {
    if (currentPage === 1) {
      return;
    }

    setPage(currentPage - 1);
  };

  // 「>」がクリックされたときの処理
  const handleForward = () => {
    if (currentPage === totalPage) {
      return;
    }

    setPage(currentPage + 1);
  };

  // ページ番号を直接クリックされたときの処理
  const handleMove = (page) => {
    setPage(page);
  };

  return (
    <div className={styles.container}>
      {/* ページ番号が0（= アイテムが0個）のときは何も描画しない */}
      {totalPage !== 0 && (
        <>
          <ul>
            <li className={
              currentPage === 1
                ? 'arrow current'
                : 'arrow'
              } onClick={() => handleBack()}>＜</li>
            {[...Array(totalPage).keys()].map(page => {
              page++
              return (page >= currentPage - pageRange && page <= currentPage + pageRange) && (
                page === currentPage ? (
                  <li className={'active'} key={page} onClick={() => handleMove(page)}>
                    {page}
                  </li>
                ) : (
                  <li key={page} onClick={() => handleMove(page)}>
                    {page}
                  </li>
                )
              )
            })}
              <li className={
                currentPage === totalPage
                  ? 'arrow current'
                  : 'arrow'
                } onClick={() => handleForward()}>＞</li>
          </ul>
        </>
      )}
    </div>
  );
};

export default Pagination