import React from 'react'
import { Link } from 'react-router-dom';
import styles from '../../../styles/organisms/header.module.scss'

const HeaderMenu = () => {
  return (
    <ul className={styles.header__menu}>
      <li><Link to="/">HOME</Link></li>
      <li><Link to="/works">WORKS</Link></li>
      <li><Link to="/access">ACCESS</Link></li>
      <li><Link to="/contact">CONTACT</Link></li>
      <li><Link to="/profile">PROFILE</Link></li> 
      <li><a href="https://ameblo.jp/ponkotsuaniki0713" target="_blank" rel='noreferrer noopener'>BLOG</a></li>
    </ul>
  )
}

export default HeaderMenu