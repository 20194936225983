import React from "react";
import { Marker } from "@react-google-maps/api";

// import React, { useState } from "react";
// import { Marker, InfoWindow } from "@react-google-maps/api";

const PlaceInfo = () => {

  const places = [
    { info: "Needle Line is here.", location: { lat: 35.8197392, lng: 140.0042556, } },
  ];

  // const [selected, setSelected] = useState(null);
  // const [size, setSize] = useState(undefined)
  // const createOffsetSize = () => {
  //   return setSize(new window.google.maps.Size(0, -45));
  // }

  // const infoWindowOptions = {
  //   pixelOffset: size,
  // }

  return (
    <>
      {places.map((marker) => (
        <Marker
          key={`${marker.location.lat * marker.location.lng}`}
          position={{
            lat: marker.location.lat,
            lng: marker.location.lng,
          }}

          // onMouseOver={() => {
          //   setSelected(marker);
          //   // マウスオーバーで<InfoWindow>が描画されます。
          // }}
        />
      ))}

      {/* {selected ? (
        // MarkerにマウスオーバーされたときにInfoWindowが表示されます。
        <InfoWindow
          position={{
            lat: selected.location.lat,
            lng: selected.location.lng,
          }}
          onCloseClick={() => setSelected(null)}
          options={infoWindowOptions}
        >
          <div>{selected.info}</div>
        </InfoWindow>
      ) : null} */}
    </>
  );
}

export default PlaceInfo