import React from 'react'
import styles from '../../../styles/organisms/header.module.scss'

const HeaderLogo = () => {
  return (
    <div className={styles.header__logo}>
      <img src="/logo400.png" alt="Needle Line" />
    </div>
  )
}

export default HeaderLogo