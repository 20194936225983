import { Header } from './compornents/organisms/Header'
import { BrowserRouter as Router ,  Switch, Route } from 'react-router-dom';
// import { Home } from './compornents/organisms/Home'
import { Access } from './compornents/organisms/Access'
import { Contact } from './compornents/organisms/Contact'
import { Gallery } from './compornents/organisms/Gallery'
import { Profile } from './compornents/organisms/Profile'
import { Works } from './compornents/organisms/Works'
import styles from './styles/app.module.scss'

function App() {
  return (
    <Router>
      <div className={styles.app}>
        <Header />

        <main className={styles.main}>
          <Gallery />

          <Switch>
            <Route path='/works'>
              <Works />
            </Route>

            <Route path='/access'>
              <Access />
            </Route>

            <Route path='/contact'>
              <Contact />
            </Route>

            <Route path='/profile'>
              <Profile />
            </Route>

            {/* <Route path='/'>
              <Home />
            </Route> */}
          </Switch>          
        </main>
      </div>
    </Router>
  );
}

export default App;
