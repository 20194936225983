import React from 'react'
import styles from '../../../styles/organisms/header.module.scss'
import { HeaderMenu, HeaderLogo } from '../../atoms/Header'

const Header = () => {
  return (
    <header className={styles.header} >
      <HeaderLogo />
      <HeaderMenu />
    </header>
  )
}

export default Header