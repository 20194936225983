import React, { useCallback, useRef } from "react"
import { GoogleMap, useLoadScript } from "@react-google-maps/api"
import { PlaceInfo } from "../../atoms/Access";
import styles from '../../../styles/organisms/access.module.scss'

// 地図のデザインを指定することができます。https://snazzymaps.com 
// import mapStyles from "./mapUtils/mapStyles"

const libraries = ["places"]
const mapContainerStyle = {
  height: "100%",
  width: "100%",
}

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
}

const Access = () => {

  const {loadError, isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_googleMapsApiKey,
    libraries,
  })

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, [])

  if (loadError) return "Error"
  if (!isLoaded) return "Loading..."
  
  return (
    <div className={styles.access}>
        <GoogleMap
          id="map"
          mapContainerStyle={mapContainerStyle}
          zoom={18}
          center={{ lat: 35.8197392, lng: 140.0042556 }}
          options={options}
          onLoad={onMapLoad}
        >
          <PlaceInfo />
        </GoogleMap>
    </div>
  )
}

export default Access