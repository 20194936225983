import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useModal } from 'react-hooks-use-modal'
import { Pagination } from '../../atoms/Pagination'
import styles from '../../../styles/organisms/works.module.scss'

const Works = () => {
  const [items, setItems] = useState([])
  const [paramsPage, setParamsPage] = useState('1')
  const [sum, setSum] = useState(0)
  const history = useHistory()
  const perPage = 12
  const containerEl = useRef(null)
  const [Modal, open, close, isOpen] = useModal('root')
  const [modalImage, setModalImage] = useState('')
  const [modalStyle, setModalStyle] = useState('vertical')

  // setParamsPage(params.page)

  useEffect(() => {
    const container = containerEl.current
    container.scrollTo(0, 0)

    fetch(process.env.REACT_APP_endpoint_items + '&per_page=' + perPage + '&page=' + paramsPage)
      .then((response) => {
        setSum(response.headers.get('x-wp-total'))
        return response.json()
      })
      .then((res) => {
        const ary = []
        res.forEach((item) => {
          const listItem = {}
          listItem.id = item.id
          listItem.image = item.item_meta.item_image
          listItem.srcset = item.item_meta.item_image_srcset
          listItem.imageShape = item.item_meta.item_image_shape
          listItem.link = item.item_meta.item_link
          listItem.title = item.title.rendered
          ary.push(listItem)
        })
        setItems(ary)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [paramsPage])

  const onChange = (props) => {
    setParamsPage(String(props.page))
    history.push('/works/?page=' + props.page)
  }

  const handleModal = useCallback(
    (imageUrl, imageShape) => {
      setModalStyle(imageShape)
      setModalImage(imageUrl)
      open()
    },
    [open]
  )

  return (
    <>
      <div className={styles.container} ref={containerEl}>
        <div className={styles.wrapper}>
          <div className={styles.images}>
            {items.map((item) => {
              return (
                <div className='item' key={item.id}>
                  <div className='image_wrapper'>
                    {item.link !== '' ? (
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        <h1>{item.title}</h1>
                        <img src={item.image} srcSet={item.srcset} alt='' />
                      </a>
                    ) : (
                      <>
                        <button onClick={() => handleModal(item.image, item.imageShape)}>
                          <h1>{item.title}</h1>
                          <img src={item.image} alt='' />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>

          <div className={styles.pagenation}>
            <Pagination sum={sum} per={perPage} onChange={onChange} />
          </div>
        </div>
      </div>

      <Modal>
        <div className={styles.modal}>
          <button onClick={close}>
            <img src={modalImage} alt='' className={modalStyle} />
          </button>
        </div>
      </Modal>
    </>
  )
}

export default Works
